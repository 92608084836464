import {createAction} from "../../utils/reducer/reducer.utils";
import {USER_ACTION_TYPES} from "./user.types";

export const setCurrentUser = (user) => createAction(USER_ACTION_TYPES.SET_CURRENT_USER, user)

export const checkUserSession = () => createAction(USER_ACTION_TYPES.CHECK_USER_SESSION)

export const googleSignInStart = () => createAction(USER_ACTION_TYPES.GOOGLE_SIGN_IN_START)

export const emailSignInStart = (email, password) => createAction(USER_ACTION_TYPES.EMAIL_SIGN_IN_START, {
    email,
    password
})

export const emailSignUp = (email, password, displayName) => createAction(USER_ACTION_TYPES.SIGN_UP_START, {
    email,
    password,
    displayName
})

export const userSignUp = (user, displayName) => createAction(USER_ACTION_TYPES.SIGN_UP_SUCCESS, {user, displayName})

export const userSignUpFail = (error) => createAction(USER_ACTION_TYPES.SIGN_IN_FAILURE,error)

export const signInSuccess = (user) => createAction(USER_ACTION_TYPES.SIGN_IN_SUCCESS, user)

export const signInFailure = (error) => createAction(USER_ACTION_TYPES.SIGN_IN_FAILURE, error)

export const signOutAction = () => createAction(USER_ACTION_TYPES.SIGN_OUT_START)

export const signOutSuccess = () => createAction(USER_ACTION_TYPES.SIGN_OUT_SUCCESS)

export const signOutFailed = () => createAction(USER_ACTION_TYPES.SIGN_OUT_FAILURE)