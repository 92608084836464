import styled from "styled-components";


export const CatContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;


`


export const CatItems = styled.div`

  display: flex;
  flex-direction: row;
  justify-content: start;
  flex-wrap: wrap;

`
