import Directory from "../../components/directory/directory.component";

function Home() {




    return (


        <Directory
        />


    )

}

export default Home;
